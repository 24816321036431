import { APP_ID, Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieModule } from 'ngx-cookie';
import { environment } from 'environments/environment';
import { DEFAULT_LANG_FUNCTION } from '@gilsdav/ngx-translate-router';
import { CommonModule, isPlatformServer } from '@angular/common';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { DataLogInterceptor } from './shared/services/data.log-interceptor';
import { UserAgentService } from './shared/services/utilities/user-agent.service';
import { datadogRum } from '@datadog/browser-rum'
import { KOZIKAZA_LOG_INTERCEPTORS, KozikazaApiModule, TokenCookieStorage, } from '@adeo/ngx-kozikaza-api';
import { CoreModule } from './modules/core/core.module';
import { LocalizedRouterService } from './shared/services/localized-router.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RoutesService } from './shared/services/routes/routes.service';
import { merge, of } from 'rxjs';
import { HeaderModule } from './modules/features/header/header.module';
import { FooterModule } from './modules/features/footer/footer.module';
import { LazyComponent } from './modules/lazy/lazy.component';
import { DateService } from './shared/services/utilities/date.service';
import { enGB, es, fr, pl } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { NotificationsApiModule } from '@adeo/ngx-kozikaza-api/notifications';
import { PostApiModule } from '@adeo/ngx-kozikaza-api/post';
import { FriendApiModule } from '@adeo/ngx-kozikaza-api/friend';
import { DataLayerService } from './shared/services/utilities/data-layer.service';
import { MaintenanceModule } from 'src/app/modules/maintenance/maintenance.module';
import { ModalsModule } from "./utils/modals/modals.module";
import { provideClientHydration } from "@angular/platform-browser";

const locales = { enGB, fr, es, pl };

export class AppMissingTrans implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams): any {
        return params.key;
    }
}

@NgModule({
  imports: [
    CommonModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CookieModule.forRoot({
      path: '/',
      domain: environment.COOKIE_DOMAIN
    }),
    KozikazaApiModule.forRoot({
      domain: environment.COOKIE_DOMAIN,
      production: environment.production,
      tokenStoragePrefix: environment.env === 'prod' ? '' : environment.env,
      kazaplanTokenStoragePrefix:environment.env === 'prod' ? '' : environment.env,
      oauthDomain: environment.REACHFIVE_DOMAIN,
      API_KAZAPLAN_V41:environment.API_KAZAPLAN_V41,
      oauthRedirectUri: environment.REACHFIVE_REDIRECT_URI,
      oauthFirstParty: {
        clientId: environment.REACHFIVE_CLIENT_ID_FIRST_PARTY,
        clientSecret: environment.REACHFIVE_CLIENT_SECRET_FIRST_PARTY,
      },
      tokenStorage: TokenCookieStorage,
      apiBaseUrl: environment.API_BACKEND_ORIGIN,
      // defaultWebServiceOptions: {
      //   headers: new HttpHeaders({
      //     'Accept': '*/*',
      //   })
      // }
    }),
    NotificationsApiModule,
    PostApiModule,
    FriendApiModule,
    HeaderModule,
    FooterModule,
    MaintenanceModule,
    ModalsModule,
  ],
    declarations: [
        AppComponent,
        LazyComponent,
    ],
    providers: [
        {
            provide: DEFAULT_LANG_FUNCTION,
            useFactory: (locale: string) => () => locale,
            deps: [LOCALE_ID]
        },
        {
            provide: 'googleTagManagerId',
            useValue: environment.GTM_ID
        },
        LocalizedRouterService,
        UserAgentService,
      {
        provide: KOZIKAZA_LOG_INTERCEPTORS,
        multi: true,
        useClass: DataLogInterceptor,
      },
      {
        provide: APP_ID,
        useValue: 'kozikaza',
      },
      provideClientHydration(),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private routesService: RoutesService,
        private dateService: DateService,
        private dataLayerService: DataLayerService,
        public config: DateFnsConfigurationService,
        private readonly localizedRouterService: LocalizedRouterService,
        @Inject(LOCALE_ID) private locale: string,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        this.dateService.locale = locales[this.locale === 'en' ? 'enGB' : this.locale];
        this.config.setLocale(this.dateService.locale);
        merge(
            this.router.events
                .pipe(
                    tap((event) => this.routesService.setEvents(event)),
                    filter((event) => event instanceof NavigationEnd),
                    tap((event: NavigationEnd) => this.routesService.setNavigationEnd(event)),
                    switchMap((event: NavigationEnd) => {
                      const rootRouteData = this.dataLayerService.getNavigationData();
                      if (isPlatformServer(this.platformId) && rootRouteData.primary?.path) {
                        if (!this.routesService.routeHasAnOutlet()) {
                          return this.localizedRouterService.translatePath(rootRouteData.primary?.path, 'en').pipe(
                            map((translatedPath) => {
                              // TODO : set english path for Varnish key
                              return event;
                            })
                          )
                        } else {
                          // response.setHeader("X-No-Cache", "true");
                        }
                      }
                      return of(event);
                    }),
                    map(() => {
                        let child = this.activatedRoute.firstChild;
                        while (child) {
                            if (child.firstChild) {
                                child = child.firstChild;
                            } else if (child.snapshot) {
                                return child.snapshot;
                            } else {
                                return null;
                            }
                        }
                        return null;
                    }),
                    tap((snapshot) => {
                        if (snapshot.data) {
                            this.routesService.setRouteDatas(snapshot.data);
                        }
                        this.routesService.setRouteSnapshot(snapshot);
                    })
                )
        ).subscribe();

      datadogRum.init({
        applicationId: environment.DATADOG.APPLICATION_ID,
        clientToken: environment.DATADOG.CLIENT_TOKEN,
        site: 'datadoghq.eu',
        service:'kozikaza-front',

        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        env: environment.env,
        sampleRate: 100,
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input',
        allowedTracingOrigins: [environment.REDIRECT_URL, environment.API_BACKEND_ORIGIN, environment.API_KAZAPLAN]
      });

      datadogRum.startSessionReplayRecording();
    }
}
