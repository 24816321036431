import { LogLevel } from '@adeo/ngx-kozikaza-api';

export const environment = {
  production: true,
  enableKzUIRoute: true,
  env: 'preprod',
  LOG_LEVEL: LogLevel.WARN,
  REDIRECT_URL: 'https://preprod-www.kozikaza.com',
  REDIRECT_URL_PT: 'https://preprod-www.kozikaza.pt',
  CLIENT_ID: '28iihz8gat0kc4wcc40swc80cogoc808wg8s4ksggkowo4c4wc',
  SECRET_KEY: '3bivrfvm3am8sk0wsk88ooc8s8ggwsowsss0800scc8c8swkc0',
  API_BACKEND_ORIGIN: 'https://preprod-www.kozikaza.com/api',
  API_SOCKET_ORIGIN: 'https://preprod-api.kozikaza.com',
  BLOG_URL: 'https://preprod-www.kozikaza.com/blog/',
  API_BLOG_ORIGIN: 'https://preprod-www.kozikaza.com/blog/wp-json/wp/v2/',
  API_BLOG_ACCESS_TOKEN: 'a3otYXBpLWNsaWVudDpyRFhxIFRYZzEgbjNqWSBQa3V5IDQ4b2wgbDFEcQ==',
  API_KAZAPLAN: 'https://preprodapi.kazaplan.com/v4',
  API_KAZAPLAN_V: 'https://api-v41.dev.kazaplan.com',
  API_KAZAPLAN_V41: 'https://api-v41.preprod.kazaplan.com',
  URL_RESIZE: 'https://img-preprod.kozikaza.com',
  URL_RESIZE_KEY: 'm',
  URL_RESIZE_BUCKET_KEY: 'kzkz-bucket',
  URL_RESIZE_MEDIA_HOST: 'media-preprod.kozikaza.com',
  URL_RESIZE_BUCKET_ASSETS_KEY: 'kzkz-assets-bucket',
  URL_RESIZE_MEDIA_ASSETS_HOST: 'preprod-www.kozikaza.com',
  URL_RESIZE_BUCKET_PRO_KEY: 'kzkz-pro-bucket',
  URL_RESIZE_MEDIA_PRO_HOST: 'media-preprod-pro.kozikaza.com',
  URL_BUCKET_ASSETS: 'https://preprod-assets.kozikaza.com',
  FACEBOOK_API_ID: '485234978498286',
  FACEBOOK_API_VERSION: 'v2.10',
  FACEBOOK_PAGE_ID: '136934696365648',
  GOOGLE_API_ID: '195388098363-tu8k133urivv04pd2a0pdoetu4itlj82.apps.googleusercontent.com',
  GOOGLE_TRANSLATE_API_ID: 'AIzaSyBqbmMtyYKdrPJGji8stuVicaR8NhO__pw',
  COOKIE_DOMAIN: 'preprod-www.kozikaza.com',
  COOKIE_USER_ID_NAME: 'userId',
  COOKIE_KAZA_ID_NAME: 'kazaId',
  COOKIE_EXPIRED_AT_NAME: 'expired_at',
  REFRESH_TOKEN_LIFE_TIME: 1209600,
  KAZAPLAN_SCRIPT: 'https://preprod-static.kazaplan.com/integration/widget/kazaplan.js',
  KAZAPLAN_ENV: 'preprod',
  KAZAPLAN_KEY: '595a5d832f256',
  COOKIE_KAZAPLAN_KEY_NAME: 'kazaplan_partner_key',
  KAZAPLAN_KEYS: [
    { countryCode: 'FR', partnerKey: '595a5d832f256', appId: 'Kozikaza'},
    { countryCode: 'ES', partnerKey: '60a66472d44f6', appId: 'KozikazaEspagne'},
    { countryCode: 'PL', partnerKey: '6040a809376e8', appId: 'KozikazaPologne'},
  ],
  KAZAPLAN_ENVIRONMENT: 'test',
  SHOW_FOOTER_LANGUAGE_SWITCH: true,
  ALLOWED_LANGUAGES: ['fr', 'en', 'pl', 'es'],
  ALLOWED_LANGUAGES_LABELS: [
    { lang: 'fr', label: 'Français'},
    { lang: 'en', label: 'English'},
    { lang: 'pl', label: 'Polaco'},
    { lang: 'es', label: 'Español'},
  ],
  ENABLE_I18N: true,
  GTM_ID: 'GTM-T5RQK88',
  REACHFIVE_CLIENT_ID_FIRST_PARTY: 'hO8QkQKlwtdtsLYF48AO',
  REACHFIVE_CLIENT_SECRET_FIRST_PARTY: 'KSExgHGeuyial1OebML7LC2yygsvy2LABJewHzVz',
  REACHFIVE_DOMAIN: 'kozikaza-preprod.reach5.net',
  REACHFIVE_REDIRECT_URI: 'https://v6-preprod-www.kozikaza.com',
  REACHFIVE_SCOPE: 'email full_write openid phone plan:read plan:write profile user:wanaplans offline_access media:read',
  SENTRYDSN: 'https://6aa8f24d19014e3384ca55ab3b7159e8@sentry.io/1833699',
  PRO: {
    ARCHITECT_JOB: '/jobs/6eda0ab1-ad21-4401-ab0c-d5c2c41fb38f',
    DECORATOR_JOB: '/jobs/7f9c7e19-44c3-4f4d-ab99-c27fa6a33dfa',
  },
  SHOPIFY_URL: 'https://kozikaza-store.myshopify.com/',
  STRIPE_PUBLIC_KEY: 'pk_test_51JWjXMLOdweT4EMmwwSgDl9gpndWGt3ScEajxI7jMn2cmxROUgMQ1mWdTcQD0J6hyRjp5KkdnNJwGzDWXwVLfJtl00KRHvGmE7',
  INSPIRATION: {
    INSPIRATION_PROJECT_BATHROOM_ID: 3392979,
    INSPIRATION_PROJECT_KITCHEN_ID: 1209807,
    INSPIRATION_PROJECT_DRESSING_ID: 2090302,
  },
  DATADOG: {
    APPLICATION_ID: '4272d4fd-55d8-4909-87b7-8ed35560e19d',
    CLIENT_TOKEN: 'pubdb6f7dec573088ae0c39c02dec9474a7'
  },
  ACCES_TOKEN:'preprod_kozikaza_token_accessToken',
  KZP_ACCES_TOKEN:'preprod_kazplan_token_accessToken',

};
